import { useEffect, useState } from "react";
import { supabase } from "./client";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Table from "react-bootstrap/Table";
import Calendar from "react-calendar";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Accordion } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function App() {
  const [categories, setCategories] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [receipt, setReceipt] = useState([
    {
      create_at: "",
      catid: 0,
      purchase_date: "",
      amount: 0,
      file_name: "",
    },
  ]);

  // const [receiptUpd, setReceiptUpd] = useState([
  //   {
  //     create_at: "",
  //     catidUpd: 0,
  //     purchase_dateUpd: "",
  //     amountUpd: 0,
  //   },
  // ]);
  // const { catidUpd, amountUpd } = receiptUpd;
  // const [receiptToUpdateObj, setReceiptToUpdateObj] = useState({
  //   file_name: "",
  //   catid: "",
  //   purchase_date: "",
  //   amount: 0,
  // });

  const [receiptToUpdate, setReceiptToUpdate] = useState({
    catid: 0,
    purchase_date: "",
    amount: 0,
    file_name: "",
  });

  const { catid, amount } = receipt;

  const [purchaseDate, setPurchaseDate] = useState(new Date());
  // const [purchaseDateUpd, setPurchaseDateUpd] = useState(new Date());
  const [user, setUser] = useState(null);
  const [file, setFile] = useState([
    {
      filename: null,
    },
  ]);
  const [files, setFiles] = useState([
    {
      files: null,
    },
  ]);
  // const [path, setPath] = useState("");
  const [authorizedUser, setAuthorizedUser] = useState(null);
  const [show, setShow] = useState(false);
  const [showUpdateReceipt, setShowUpdateReceipt] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleReceiptUpdateClose = () => setShowUpdateReceipt(false);
  // const handleShowUpdateReceipt = () => setShowUpdateReceipt(true);

  const handleUploadFiles = async () => {
    for (let i = 0; i < files.filename.length; i++) {
      const { data, error } = await supabase.storage
        .from("receipts")
        .upload(`public/${files.filename[i].name}`, files.filename[i]);
      await supabase.from("receipts").insert([
        {
          catid: 6, //unassigned
          purchase_date: Moment(new Date()).format("YYYY-MM-DD"),
          amount: 0, //amount to be updated
          file_name: data.path,
        },
      ]);
    }
    handleClose();
    fetchReceipts();
  };

  useEffect(() => {
    checkUser();
    window.addEventListener("hashchange", function () {
      checkUser();
    });
  }, []);

  async function checkUser() {
    supabase.auth.getUser().then(function (user) {
      if (user.data.user.email) {
        setUser(user.data.user);
      } else {
        setUser(null);
      }
    });
  }

  async function signIn() {
    return await supabase.auth.signInWithOAuth({
      provider: "google",
    });
  }

  async function signOut() {
    await supabase.auth.signOut();
    setUser(null);
  }

  async function uploadFile() {
    const { data, error } = await supabase.storage
      .from("receipts")
      .upload(file.filename.name, file.filename);
    // if (data.path !== "") {
    //     return data.path;
    // } else if (error) {
    //    return "";
    return data.path;
  }

  // async function getReceiptImage(receipt) {
  // const { data } = await supabase
  //   .from("receipts")
  //   .select("file_name, catid, amount, purchase_date")
  //   .eq("id", receiptId);
  // setReceiptUpd({
  //   catidUpd: data[0].catid,
  //   purchaseDateUpd: Moment(data[0].purchase_date).format("YYYY-MM-DD"),
  //   amountUpd: data[0].amount,
  // });
  // setPurchaseDateUpd(
  //   new Date(
  //     Moment(receipt.purchase_date).format("YYYY"),
  //     Moment(receipt.purchase_date).format("MM") - 1,
  //     Moment(receipt.purchase_date).format("DD")
  //   )
  // );
  // setReceiptToUpdateObj({
  //   file_name: receipt.file_name,
  //   // catid: data[0].catid,
  //   // amount: data[0].amount,
  //   // purchase_date: data[0].purchase_date,
  // });
  // }

  async function handleReceiptUpdate() {
    // console.log(Moment(receiptToUpdate.purchase_date).format("YYYY-MM-DD"));
    await supabase
      .from("receipts")
      .update({
        catid: parseInt(receiptToUpdate.catid),
        purchase_date: Moment(receiptToUpdate.purchase_date).format(
          "YYYY-MM-DD"
        ),
        amount: parseFloat(receiptToUpdate.amount),
      })
      .eq("id", receiptToUpdate.id);

    setShowUpdateReceipt(false);
    fetchReceipts();
  }

  async function fetchCategories() {
    const { data } = await supabase
      .from("receipt_categories")
      .select("id,name");
    setCategories(data);
  }

  async function fetchReceipts() {
    const { data } = await supabase.from("receipts").select(`
    id,
    catid,
    receipt_categories (
      name
    ),amount, purchase_date, file_name
  `);
    setReceipts(data);
  }

  async function getAuthorizedUser() {
    const { data } = await supabase
      .from("receipts_users")
      .select("email, role");
    setAuthorizedUser(data);
  }

  async function createReceipt() {
    let filepath = await uploadFile();

    // if (path !== "") {
    let formattedDate = Moment(purchaseDate).format("YYYY-MM-DD");

    await supabase.from("receipts").insert([
      {
        catid: catid || 6,
        purchase_date: formattedDate,
        amount,
        file_name: filepath,
      },
    ]);

    // .single()
    // .then(function () {
    //   setPath(null);
    // });
    // } else {
    //   console.log("WTF");
    // }

    fetchReceipts();
  }

  useEffect(() => {
    Moment.locale = "en";
    fetchCategories();
    fetchReceipts();
    getAuthorizedUser();
  }, []);

  if (
    user &&
    authorizedUser &&
    authorizedUser.length > 0 &&
    authorizedUser.filter((e) => e.email === user.user_metadata.email)
      .length === 1
  ) {
    return (
      <>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="#">Receipt Capture</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#">
                  {user.user_metadata.full_name}({user.user_metadata.email})
                </Nav.Link>
                <Nav.Link href="#">
                  <button onClick={signOut}>Sign Out</button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <br />
        <Container>
          {authorizedUser.filter((e) => e.email === user.user_metadata.email)[0]
            .role === 1 ? (
            <>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Upload Single Receipt</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formCategory">
                          <Form.Label>Category</Form.Label>
                          <Form.Select
                            size="sm"
                            value={catid}
                            onChange={(e) => {
                              setReceipt({ ...receipt, catid: e.target.value });
                            }}
                          >
                            {categories.map((category) => {
                              return (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Form.Label>File</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setFile({ ...file, filename: e.target.files[0] })
                            }
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            value={amount}
                            onChange={(e) =>
                              setReceipt({ ...receipt, amount: e.target.value })
                            }
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Form.Label>Date</Form.Label>
                          <Calendar
                            onChange={(value) => setPurchaseDate(value)}
                            value={purchaseDate}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Button onClick={createReceipt}>Add Receipt</Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <p>
                <Button onClick={handleShow}>Upload Multiple Receipts</Button>
              </p>
            </>
          ) : (
            <></>
          )}

          <DataTable
            value={receipts}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            selectionMode="single"
            // selection={selectedReceipt}
            onSelectionChange={(e) => {
              setShowUpdateReceipt(true);
              setReceiptToUpdate(e.value);
              // getReceiptImage(e.value);
            }}
            dataKey="id"
            metaKeySelection={false}
          >
            <Column
              field="receipt_categories.name"
              sortable
              header="Category"
            ></Column>
            <Column field="amount" sortable header="Amount"></Column>
            <Column
              field="purchase_date"
              sortable
              header="Purchase Date"
            ></Column>
          </DataTable>

          {/* <Row>
            <Col>
              <Table striped bordered hover style={{ fontSize: "12px" }}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {receipts.map((receipt) => {
                    return (
                      <tr>
                        <td>
                          <Button
                            onClick={() => {
                              setShowUpdateReceipt(true);
                              setReceiptToUpdate(receipt.id);
                              getReceiptImage(receipt.id);
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                        <td>{receipt.receipt_categories.name}</td>
                        <td>{receipt.amount}</td>
                        <td>{receipt.purchase_date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row> */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Multiple Receipts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formMultiReceipts">
                    <Form.Label>Select Receipts</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) =>
                        setFiles({ ...file, filename: e.target.files })
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formMultiReceipts"
                  ></Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleUploadFiles}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showUpdateReceipt} onHide={handleReceiptUpdateClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  size="sm"
                  value={receiptToUpdate.catid}
                  onChange={(e) =>
                    setReceiptToUpdate({
                      ...receiptToUpdate,
                      catid: e.target.value,
                    })
                  }
                >
                  {categories.map((category) => {
                    return <option value={category.id}>{category.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={receiptToUpdate.amount}
                  onChange={(e) =>
                    setReceiptToUpdate({
                      ...receiptToUpdate,
                      amount: e.target.value,
                    })
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Purchase Date</Form.Label>
                <Calendar
                  onChange={(e) =>
                    setReceiptToUpdate({
                      ...receiptToUpdate,
                      purchase_date: new Date(
                        Moment(e).format("YYYY"),
                        Moment(e).format("MM") - 1,
                        Moment(e).format("DD")
                      ),
                    })
                  }
                  value={
                    new Date(
                      Moment(receiptToUpdate.purchase_date).format("YYYY"),
                      Moment(receiptToUpdate.purchase_date).format("MM") - 1,
                      Moment(receiptToUpdate.purchase_date).format("DD")
                    )
                  }
                />
              </Form.Group>
              <img
                style={{ width: "100%" }}
                src={`${process.env.REACT_APP_FILE_STORAGE}${receiptToUpdate.file_name}`}
                alt=""
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleReceiptUpdateClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleReceiptUpdate}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </>
    );
  }
  return (
    <Container>
      <h1>Receipt Capture</h1>
      <div className="container" style={{ padding: "5px 5px" }}>
        <Button onClick={signIn}>Sign In</Button>
        &nbsp;if you have an account or request one.
      </div>
    </Container>
  );
}

export default App;
