import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  // <BrowserRouter>
  //   <Routes>
  //     <Route path="/" element={<Layout />}></Route>
  //     <Route path="/multi" element={<Multi />}></Route>
  //   </Routes>
  // </BrowserRouter>
);
